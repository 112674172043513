import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Navbar from './Component/Navbar'
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from './Component/Footer';
import ServicesDetails from "./Services/ServicesDetails";
import Home from './Component/Home';
import About from './Component/About';
import Services from './Component/Services';
import Contact from './Component/Contact';
import Pagenotfound from './Common/Pagenotfound';
import BlogsDetails from './Services/BlogDetails';
import Policy from './Component/Policy';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicesDetails/:id" element={<ServicesDetails />} />
          <Route path="/blogsDetails/:id" element={<BlogsDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="*" element={<Pagenotfound />} />
        </Routes>
      </Router>
      <Footer />
    </>
  </React.StrictMode>
);

reportWebVitals();