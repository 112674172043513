import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardImg,
  CardTitle,
  Row,
  Col,
  Container,
  CardText
} from "reactstrap";
import "../Assets/Css/Services.scss";
import { data } from "../Common/Data/fakeBackend";

function Services() {
  return (
    <div id="services" className="">
      <Container className="py-3" style={{backgroundColor:'white'}}>
        <h2 style={{textAlign:'center', fontWeight:'800'}} className="pb-3"> Services</h2>
        <Row>
          {data.services.map((service) => (
            <Col xl={3} lg={4} md={6} xs={12} key={service.id}>
              <Link to={`/servicesDetails/${service.id}`} className="services__btn_link ">
              <Card className="services__card mb-3 p-2">
                <CardImg className="services__img object-cover h-48 rounded-sm mb-2" src={service.image} 
                   />
                <CardBody>
                  <CardTitle  className="text-md sm:text-lg font-bold mt-1 line-clamp-3" tag="h5">{service.title}</CardTitle>
                  <CardText className="card__text line-clamp-2 text-sm">
                    {service.subtitle.length > 150
                      ? `${service.subtitle.substring(0, 100)}...`
                      : service.subtitle}
                  </CardText>
                </CardBody>
                
                  {/* <Button color="primary" className="services__readmore_btn group-hover:text-color-primary flex items-center justify-end mt-3 trasition duration-200">
                    Read More
                  </Button> */}
                
              </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Services;
