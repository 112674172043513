import React from "react";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Blogs from "./ui/Blogs";

function Home() {
  return (
    <div style={{width:'99.3%'}}>
      <About />
      <Services />
      <br/>
      <Portfolio />
      <br/>
      <br/>
      <Blogs/>
      <br/>
      <br/>
      <Contact />
    </div>
  );
}

export default Home;
