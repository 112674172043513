import React from 'react'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

function Policy() {
  return (
    <Container style={{ marginTop: " 110px" }}>
    <div>
      <div id="services">
        <Row style={{justifyContent: "center"}}>
                <Row>
                  <Col xs="12" md="8">
                    <Card>
                      <CardBody>
                        <h2 className="mb-4">Privacy Policy for LiqoTech</h2>
                      
                        <h3>Introduction</h3>
                        <br/>
                        <h6>Welcome to LiqoTech.</h6>
                        <p> We are dedicated to protecting the privacy of our clients and website visitors. This policy details our approach to handling your personal information.</p>
                        <h6>Information We Collect</h6>
                        <p>
We may gather information like your name, contact details, and payment information during interactions with our website, services, or customer support.</p>
                       <h6>Use of Your Information</h6>
                        <p>
We utilize this information for transaction processing, service improvement, customer support, and, with your consent, marketing communications.</p>
                       <h6>Sharing of Information</h6>
                        <p>
Your data is not sold or rented out. It may be shared with partners for specific services under strict confidentiality agreements.</p>
                    <h6>Data Security</h6>
                   <p>We employ rigorous security measures to protect your information.</p>
                   <h6>Cookies</h6>
                   <p>Our website uses cookies to enhance user experience. You have control over cookie acceptance.</p>
                     <h6>Your Rights</h6>
<p>You can access, amend, or request deletion of your personal data.</p>
<h6>Policy Changes</h6>
<p>We may update this policy, with changes posted here.</p>

                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" md="4">
                    <Card>
                      <CardBody>
                        <h4 className="mb-4">Contact Us</h4>
                        <p>
                          If you're interested in our web development services
                          or have any questions, please feel free to contact
                          us using the information below:
                        </p>
                        <ul>
                          <li>Email: info@liqotech.com</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
        </Row>
      </div>
    </div>
  </Container>
  )
}

export default Policy