import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardImg,  CardTitle, Col, Container, Row } from 'reactstrap';
import { data } from "../Common/Data/fakeBackend";

function ServicesDetails() {
  const { id } = useParams(); 
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Container style={{ marginTop: " 110px" }}>
      <div>
        <div id="services">
          <Row style={{justifyContent: "center"}}>
            {data.services.map((service) => {
              if (service.id === id) {
                return (
                  <Row key={service.id}>
                    <Col xs="12" md="8">
                      <Card>
                        <CardBody>
                          <h2 className="mb-4">{service.title}</h2>
                          <CardImg
                            src={service.image}
                            style={{
                              width: "80%",
                              height: "400px",
                              objectFit: "cover",
                            }}
                            // style={{ height: "300px", width: "500px" }}
                          />
                          <h3>{service.subtitle}</h3>
                          <p>{service.description1}</p>
                          <p>{service.description2}</p>
                          <p>{service.description3}</p>
                          <p>{service.description4}</p>
                          {/* {data.services.map((our) => {

                          })} */}
                          <h4 className="mt-5 mb-4">
                            {service.ourServices[0].head}
                          </h4>
                          <CardTitle>{service.ourServices[0][1]}</CardTitle>
                          <CardTitle>{service.ourServices[0][2]}</CardTitle>
                          <CardTitle>{service.ourServices[0][3]}</CardTitle>
                          <CardTitle>{service.ourServices[0][4]}</CardTitle>
                          <CardTitle>{service.ourServices[0][5]}</CardTitle>
                          <CardTitle>{service.ourServices[0][6]}</CardTitle>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xs="12" md="4">
                      <Card>
                        <CardBody>
                          <h4 className="mb-4">Contact Us</h4>
                          <p>
                            If you're interested in our web development services
                            or have any questions, please feel free to contact
                            us using the information below:
                          </p>
                          <ul>
                            <li>Email: info@liqotech.com</li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                );
              }
            })}
          </Row>
        </div>
      </div>
    </Container>
  );
}

export default ServicesDetails;