import React from "react";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import "../Assets/Css/Contact.scss";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaEnvelope } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa6';


const Contact = () => {
  const [formStatus, setFormStatus] = React.useState("Send");

  const onSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, email, phone, message } = e.target.elements;
    let conFom = {
      site: "liqotech.com",
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
    };
    console.log(conFom);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    try {
      const response = await axios.post(
        "https://www.liqotech.com/api/contact",
        conFom,
        { headers }
      );
      console.log(response);
      setFormStatus("Sent");
      e.target.reset(); // reset the form
    } catch (error) {
      console.error(error);
      setFormStatus("Error");
    }

    console.log("form", formStatus);
  };

  return (
    <div style={{backgroundColor:"#f1f5f9"}}>
     
      <Row className="justify-content-center">
      <Col className="contact-container">
      <h1 className="contact-heading">
        <span className="bold-text">Liqo</span>Tech
      </h1>
      <div className="contact-details">
  <Link to="tel:+917012717783" className="contact-link" style={{ textDecoration: "none" }}>
    <h6 className="contact-link">
      <FaPhone className="contact-icon" />
      +91 7012717783
    </h6>
  </Link>
  <Link to="mailto:info@liqotech.com" className="contact-link" style={{ textDecoration: "none" }}>
    <h6 className="contact-link">
      <FaEnvelope className="contact-icon" />
      info@liqotech.com
    </h6>
  </Link>
</div>

    </Col>
       
        <Col
          className="d-flex justify-content-flex-start "
          style={{ margin: "50px" }}
        >
          <div className="flex items-center justify-center">
        <Card className="w-96 p-4 contactcard">
          
            <CardTitle style={{fontWeight:'700', fontSize:'x-large'}}>Contact Us</CardTitle>
            
              Send us a message through the contact form below
            
          
          <div className="">
            <form onSubmit={onSubmit} className="flex flex-col gap-2">
              <div className="mt-4">
                <input
                 className="w-100 inputclass"
                  maxLength={150}
                  required
                  placeholder="Name"
                  type="text"
                  autoComplete="off"
                  id="name"
                />
              </div>
              <div className="mt-2">
                <input
                 className="w-100 inputclass"
                  maxLength={150}
                  required
                  placeholder="Email"
                  type="email"
                  autoComplete="off"
                  id="email"
                />
              </div>
              <div className="mt-2">
                <input
                 className="w-100 inputclass"
                  maxLength={150}
                  required
                  placeholder="Phone Number"
                  type="tel"
                  autoComplete="off"
                  id="phone"
                />
              </div>
              <div className="mt-2">
                <textarea
                  className="w-100 inputclass"
                  rows={4}
                  required
                  name="message"
                  placeholder="Type your message here."
                />
              </div>
              <Button type="submit" className="mt-3 w-100 inputclass" style={{backgroundColor:"black"}}>
                Submit
              </Button>
            </form>
            </div>
       
        </Card>
      </div>
          {/* <NavLink href="https://wa.me/message/ZG2JGSRJK6DFL1">
            <CardImg
              src={qr}
              height="250px"
              style={{ width: "250px", justifyContent: "center" }}
            />
          </NavLink> */}
        </Col>
      </Row>
      {/* <!-- font awesome --> */}
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      {/* WhatsApp icon */}
      <a
        href="https://wa.me/message/WBGV7KELMXEJP1"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};

export default Contact;
