import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row } from 'reactstrap'
import { dataBlogs } from '../../Common/Data/DataFakeBlog'

function Blogs() {
  return (
    <div id="services" className="">
    <Container className="py-3" style={{backgroundColor:'white'}}>
      <h2 style={{textAlign:'center', fontWeight:'800'}} className="pb-3"> Our Blogs </h2>
      <Row style={{justifyContent:"center"}}>
       {dataBlogs.blogs.map((item)=>(

          <Col xl={3} lg={4} md={6} xs={12} >
            <Link to={`/blogsDetails/${item.id}`} className="services__btn_link ">
            <Card className="services__card mb-3 p-2" >
              <CardImg className="services__img object-cover h-48 rounded-sm mb-2"src={item.image}
                 />
              <CardBody>
                <CardTitle  className="text-md sm:text-lg font-bold mt-1 line-clamp-3" tag="h5">{item.title} </CardTitle>
                <CardText className="card__text line-clamp-2 text-sm">
                 {item.description}
                </CardText>
              </CardBody>
              
                {/* <Button color="primary" className="services__readmore_btn group-hover:text-color-primary flex items-center justify-end mt-3 trasition duration-200">
                  Read More
                </Button> */}
              
            </Card>
            </Link>
            
          </Col>
            ))}
      </Row>
    </Container>
  </div>
  )
}

export default Blogs