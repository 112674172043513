import React, { useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardImg, Col, Container, Row } from 'reactstrap';
import { dataBlogs } from '../Common/Data/DataFakeBlog';

function BlogsDetails() {
  const { id } = useParams();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  // Helper function to render content based on the structure of the data
  const renderContent = (sections) => {
    return sections.map((section, index) => (
      <div key={index}>
        <h3>{section.title}</h3>
        <p>{section.content}</p>
        {section.subSections &&
          section.subSections.map((subSection, subIndex) => (
            <p key={subIndex}>{subSection}</p>
          ))}
      </div>
    ));
  };

  return (
    <Container style={{ marginTop: "110px" }}>
      <div id="services">
        <Row style={{justifyContent: "center"}}>
          {dataBlogs.blogs.map((blog) => {
            if (blog.id === id) {
              return (
                <Row key={blog.id}>
                  <Col xs="12" md="8">
                    <Card>
                      <CardBody>
                        <h2 className="mb-4">{blog.title}</h2>
                        <CardImg
                          src={blog.image}
                          style={{
                            width: "80%",
                            height: "400px",
                            objectFit: "cover",
                          }}
                        />
                        {renderContent(blog.sections)}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs="12" md="4">
                    <Card>
                      <CardBody>
                        <h4 className="mb-4">Contact Us</h4>
                        <p>
                          If you're interested in our web development services
                          or have any questions, please feel free to contact
                          us using the information below:
                        </p>
                        <ul>
                          <li>Email: info@liqotech.com</li>
                        </ul>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              );
            }
          })}
        </Row>
      </div>
    </Container>
  );
}

export default BlogsDetails;
