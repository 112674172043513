import React from "react";
import { Row } from "reactstrap";
import "../Assets/Css/Footer.scss";
import { AiFillInstagram, AiFillFacebook,AiFillLinkedin  } from 'react-icons/ai';

function Footer() {
  return (
    <Row
      className="p-2"
      style={{
        backgroundColor: "white",
        color: "black",
        margin: "0",
      }}
    >
    {/* <div style={{ borderTop: "1px solid #000" }}></div> */}

      <footer className="flex items-center justify-between py-3 md:py-4 px-4 sm:px-8 xl:px-32 z-10 m-0">
     
       <span className="flex gap-3" style={{float:'right',paddingRight:"75px"}}>
          <a
            href="https://www.instagram.com/liqotech/"
            
          >
            <AiFillInstagram style={{fontSize:'30',color:'black'}}/>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100087080491664&mibextid=b06tZ0"
            
          >
            <AiFillFacebook style={{fontSize:'30',color:'black'}} />
          </a>
         
          <a
            href="https://www.linkedin.com/company/liqotech/about/"
          
          >
            <AiFillLinkedin style={{fontSize:'30',color:'black'}} />
          </a>
        </span>
        <h6 className="text-xs font-medium text-neutral-500 dark:text-gray-300">
          ©LiqoTech 2023
        </h6>
        <a
        style={{textDecoration:'none', color:'black'}}
            href="/policy"
          
          >
           Privacy Policy for LiqoTech
          </a>
        </footer>
    </Row>
  );
}

export default Footer;
