import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { DataPortflio } from "../Common/Data/PortflioFakedata";
import "../Assets/Css/portfolio.css";
import { CardImg } from "reactstrap";
import { Link } from "react-router-dom";

function Portfolio() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="w-100">
      <section className="section section--no-pt section--no-pb section--background-base-light">
        <div className="portfolios">
          <div className="gallery gallery--style-3">
            <div className="gallery__inner">
              <div className="row no-gutters">
                <div className="Fullscn">
                  <h2 className="p-4">Our Portfolio</h2>
                  <Carousel responsive={responsive} className="carousel">
                    {DataPortflio.portflio.map((service, index) => (
                      <div key={index} className="portfolio-item">
                        <Link to={service.url}>
                        <CardImg
                          className="img"
                          src={service.image}
                          alt={service.title}
                        />
                        </Link>
                        <p className="carouselTitle">{service.title}</p>
                        
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Portfolio;
