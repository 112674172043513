import React, { useEffect, useState } from "react";
import {
  Nav,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavLink,
  Collapse,
  CardImg,
} from "reactstrap";
import img1 from "../Assets/Image/LIQOTECH-LOGO.png";
import "../Assets/Css/Navbar.scss";

const MyNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavLinkClick = (target) => {
    const section = document.querySelector(target);
    if (window.location.pathname === "/") {
      // if we're already on the home page, scroll to the target section
      section.scrollIntoView({ behavior: "smooth" });
    } else {
      // if we're on a different page, redirect to the home page and scroll to the target section
      window.location.href = "/";
      setTimeout(() => {
        section.scrollIntoView({ behavior: "smooth" });
      }, 500); // wait 500 milliseconds for the home page to load before scrolling to the target section
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.pageYOffset;
      if (currentPosition > scrollPosition && !isHidden) {
        setIsHidden(true);
      } else if (currentPosition < scrollPosition && isHidden) {
        setIsHidden(false);
      }
      setScrollPosition(currentPosition);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isHidden, scrollPosition]);
  return (
    <div
      id="Navbar"
      className={`fixed-top ${isHidden ? "is-hidden" : ""}`}
    >
      <Navbar expand="md" className="navbarliqo">
        <NavbarBrand>
          <NavLink href="/">
            <CardImg
              alt="LiqoTech"
              src={img1}
              className="brand__img"
              style={{height:"100px",width:"100px"}}
            />
          </NavLink>
        </NavbarBrand>
        {/* <NavbarToggler onClick={toggle} /> */}
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/* <NavItem>
              <NavLink
                href="#about"
                onClick={(e) =>
                  handleNavLinkClick(e.target.getAttribute("href"))
                }
              >
                About Us
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#services"
                onClick={(e) =>
                  handleNavLinkClick(e.target.getAttribute("href"))
                }
              >
                Our Services
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#contact"
                onClick={(e) =>
                  handleNavLinkClick(e.target.getAttribute("href"))
                }
              >
                Contact
              </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default MyNavbar;
