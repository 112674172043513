import hot from "../../Assets/Image/logo (1).svg";
import lekochi from "../../Assets/Image/lukochi.webp";
import keind from "../../Assets/Image/kin.webp";
import aus from "../../Assets/Image/aus.png";
import vitom from "../../Assets/Image/client-1.png";
import clifton from "../../Assets/Image/clifton.webp";
import Exta from '../../Assets/Image/Logo with Name - Arabic.png'

const DataPortflio= {
    portflio:[
        {
            id:"1",
            title:"Hangitonme",
            url:"https://hangitonme.com/",
            image: vitom,
        },
        {
            id:"2",
            title:"Le Kochi",
            url:"https://lekochi.com/lekochiOne/ ",
            image: lekochi,
        },
        {
            id:"3",
            title:"Keind marine",
            url:"https://www.keindmarine.com/index.html",
            image: keind,
        },
        {
            id:"4",
            title:"Exabyte ",
            url:"http://www.exabyteict.com",
            image: Exta,
        } ,
        {
            id:"5",
            title:"Cliftons Study Abroad ",
            url:"https://cliftonsstudyabroad.com",
            image: clifton,
        },
        {
            id:"6",
            title:"AusEducationPathways",
            url:"https://auseducationpathways.com/",
            image: aus,
        },
        {
            id:"7",
            title:"Diagonal",
            url:"http://mylo.vofyd.com ",
            image: hot,
        } 

    ]
}
export {DataPortflio} 