import React from "react";
import { CardBody, Col, Container, Row } from "reactstrap";
import "../Assets/Css/About.scss";

function About() {
  return (
    <div id="about" className="about__main_div p-4" >
      <Container style={{ backgroundColor:'white', width:'100%'}}>
     
      <Row id="aboutUs">
      
        <Col>
            <h2 className="pt-3" style={{color:"orange", fontSize:42 , fontFamily:'inherit'}}>Welcome to <span style={{color:"cornflowerblue"}}>Liqo</span>Tech</h2>
          <CardBody id="aboutUsBody">
              We are software solutions providers with expertise in <span className="lineinside">crafting custom
              software development services </span> tailored to each client's specific
              requirements. We are a team of programmers & designers who are
              passionate in developing and delivering <span className="lineinside">high-quality and affordable
              software solutions to businesses of all sizes.</span> At LiqoTech, our team
              is dedicated to staying on top of the latest trends in technology to
              provide our clients with cutting-edge solutions that meet their unique
              needs.
          </CardBody>
        </Col>
      </Row>
      </Container>
    </div>
  );
}

export default About;
