import development from "../../Assets/Image/web.jpg";
import design from "../../Assets/Image/design.jpg";
import marketing from "../../Assets/Image/marketing.jpg";
import consultation from "../../Assets/Image/const.jpg";


 const data = {
   services: [
     {
       id: "1",
       title: "Design  ",
       subtitle:"Creating Visually Stunning and User-Friendly Websites",
       description1:
         "Our Design service is dedicated to crafting visually stunning and user-friendly websites. In the digital age, first impressions matter, and your website's design is the gateway to creating a lasting impact on your audience. We understand that design is not just about aesthetics but also about creating an engaging and memorable user experience. ",
         description2:"Our team of skilled designers combines creativity with functionality to produce websites that captivate and convert. We believe in the power of design to tell a story, convey a brand's personality, and guide visitors seamlessly through your online presence.",
         description3:"At the core of our Design service is the application of cutting-edge technology and innovative design principles. We leverage AI-driven design tools to create dynamic and responsive layouts that adapt to the individual preferences of users. This personalization ensures a unique and engaging experience for each visitor.",
         description4:"Whether you're looking for a brand-new design or a redesign of your existing website, our Design service has you covered. We prioritize mobile responsiveness, fast loading times, and an intuitive user interface to ensure that your website stands out in today's competitive digital landscape.",
         image: design,
       ourServices: [
         {
           head: "Our Services",
           1: "Custom website design and development",
           2: "E-commerce solutions",
           3: "Content management systems",
           4: "Mobile app development",
           5: "Search engine optimization",
           6: "Social media integration",
         },
       ],
     },
     {
       id: "2",
       title: "Development ",
       subtitle:"Building Robust, Responsive, and Scalable Web Solutions",
       description1:
         "Our Development service is all about building robust, responsive, and scalable web solutions that drive your online presence to new heights. In the modern digital era, a strong online platform is essential for success, and our development team is here to make that a reality for your business.         ",
         description2:"We take a comprehensive approach to web development, incorporating the latest technologies, security measures, and best practices to ensure that your website functions flawlessly. Our development services extend to web applications, e-commerce platforms, content management systems, and much more.",
         description3:"Artificial Intelligence plays a significant role in our Development service, particularly in the realm of user experience enhancement. We integrate AI-driven chatbots, personalization engines, and recommendation systems to create interactive and dynamic websites that adapt to user behavior. These AI-powered features improve engagement, conversion rates, and customer satisfaction.",
         description4:"We're not just about creating websites; we're about building online experiences that resonate with your audience. Our focus on responsive design and efficient coding ensures that your website looks and functions perfectly on all devices and browsers. Your online success is our priority.",
       image: development,
       ourServices: [
         {
           head: "Our Services",
           1: "Custom website design and development",
           2: "E-commerce solutions",
           3: "Content management systems",
           4: "Mobile app development",
           5: "Search engine optimization",
           6: "Social media integration",
         },
       ],
     },
     {
       id: "3",
       title: "Marketing ",
       subtitle:"Driving Online Success through SEO, Digital Advertising, and Content Strategies",
       description1:
         "Our Marketing service is dedicated to driving online success through a combination of SEO, digital advertising, and content strategies. In today's digital landscape, visibility and reach are key factors for business growth, and our marketing team is here to make your brand stand out.",
         description2:"Search Engine Optimization (SEO) is at the core of our marketing efforts. We leverage the latest SEO techniques and tools to optimize your website's content and structure, ensuring that it ranks high in search engine results. AI-powered SEO tools assist us in analyzing data, identifying trends, and fine-tuning strategies for maximum impact.",
         description3:"Digital advertising is another cornerstone of our Marketing service. Our experts create targeted ad campaigns across various online platforms, ensuring that your brand reaches the right audience at the right time. AI-driven ad optimization helps us make data-informed decisions and deliver the best return on investment for your advertising budget.",
         description4:"Content strategy is equally important. We create and distribute valuable, relevant content that resonates with your target audience. AI tools assist in content creation, analyzing audience preferences, and tracking content performance.",
       image: marketing,
       ourServices: [
         {
           head: "Our Services",
           1: "Custom website design and development",
           2: "E-commerce solutions",
           3: "Content management systems",
           4: "Mobile app development",
           5: "Search engine optimization",
           6: "Social media integration",
         },
       ],
     },
     {
       id: "4",
       title: "Consultation ",
       subtitle:"Expert Guidance for Tailored Web Development and Online Marketing Solutions",
       description1:
         "Our Consultation service is the heart of our offerings, where we provide expert guidance for tailored web development and online marketing solutions. We understand that every business is unique, and our consultation service ensures that your online strategies are personalized to your specific needs.",
         description2:"Our team of seasoned experts works closely with you to understand your business goals and challenges. AI-driven analytics and data insights help us gain a deep understanding of your industry, audience, and competition. We use this information to craft a bespoke strategy that aligns with your vision.",
         description3:"Our Consultation service is driven by a commitment to innovation, and AI plays a crucial role in this endeavor. We utilize AI technologies to analyze user data, identify trends, and make data-driven recommendations. AI-powered chatbots assist in customer interactions, offering instant support and improving user experience.",
         description4:"We're here to demystify the complex world of web development and online marketing for you. Whether you're just starting your online journey or looking to optimize your current strategies, our Consultation service offers the expertise and insights you need to make informed decisions and achieve your online goals.",
       image: consultation,
       ourServices: [
         {
           head: "Our Services",
           1: "Custom website design and development",
           2: "E-commerce solutions",
           3: "Content management systems",
           4: "Mobile app development",
           5: "Search engine optimization",
           6: "Social media integration",
         },
       ],
     },
   ],
 };



export {data} 