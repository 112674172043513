import React from "react";
import { Link } from "react-router-dom";
function Pagenotfound() {
  return (
    <div className="m-4 ,p-4">
      <br />
      <br />
      <br />
      <div>
        <h1 style={{ color: "#06386D", textAlign: "center" }}>
          Oops! 404 <br />
          <br />
          <h6 style={{ color: "black" }}>PAGE NOT FOUND </h6>
          <h6 style={{ color: "black" }}>
            <br />
            Sorry, but the page you were trying to view does not exist.
            <br />
            <br />
            <span>
              <Link to={"/"}>
                {" "}
                <button
                  style={{
                    marginRight: "2px",
                    color: "white",
                    backgroundColor: "#06386D",
                    borderRadius: 7,
                  }}
                >
                  Back To Home
                </button>
              </Link>
            </span>
          </h6>
        </h1>
      </div>
    </div>
  );
}

export default Pagenotfound;
